<template>
  <!-- 报警事件 -->
  <div class="purchaseManage">
    <!-- 板块中心区域 -->
    <div class="purchaseManage_mid">
      <div class="purchaseManage_header">
        <!-- 标题 -->
        <div class="title">
          <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="">
          <p v-for="(item,index) in $route.meta" :key="index">{{ item }}</p>
        </div>
        <!-- 选项框和按钮 -->
        <div class="option_btn">
          <div class="options">
            <!-- Element ui--Select 选择器 -->
            <!-- 报警类型 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">报警类型</span>
              <el-select v-model="jtype" placeholder="请选择" style="margin:0 1.45vw 0 0">
                <el-option v-for="item in warningSort" :key="item.id" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </template >
          </div>
          <div class="flushed">
            <img src="../../assets/image/warningInform_slices/group_342.png" alt="" @click="flushed" v-loading.fullscreen.lock="fullscreenLoading">
          </div>
        </div>
      </div>

      <!-- 表格区域 -->
      <div class="gateway_form">
        <lists ref="getmethod"></lists>
      </div>
    </div>
  </div>
</template>

<script>
// 引入vuex的辅助函数
import { mapState } from 'vuex';
// 引入列表页
import lists from "./components/lists";
export default {
  components: {
    lists
  },
  computed: {
   ...mapState('warningInform',['warningSort','warningLevel'])
  },
  data () {
    return {
      fullscreenLoading:false,
      jtype: ''
    };
  },
  watch: {
    jtype() {
      this.$refs.getmethod.params.jtype = this.jtype
    }
  },
  methods: {
    flushed(){
      this.$refs.getmethod.getAlarmList()
    },
  },
};
</script>

<style  scoped>

.purchaseManage{
  height: 100%;
  display: flex;
  justify-content: center;
  margin: -30px 0 0 0;
}
.purchaseManage .purchaseManage_mid{
  width: 100%;
  height: 100%;
}
/* 头部--开始 */
.purchaseManage_mid .purchaseManage_header{
  width: 100%;
  min-height: 134px;
  background-color: #fff;
}
.purchaseManage_header .title {
  display: flex;
  align-items: flex-end;
}
.purchaseManage_header .title img {
  width: 6px;
  height: 20px;
  margin: 20px 0 0 20px;
}
.purchaseManage_header .title p {
  font-size: 20px;
  font-family: "SHSCN_Bold";
  margin: 1vw 0 0 0.6vw;
}
/* 选项框和按钮 */
.option_btn {
  width: 100%;
  height: 7.2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option_btn /deep/ .el-input{
  width: 206px;
  height: 32px;
}
.options{
  height: 7.2vh;
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.option_btn /deep/ .el-input__icon{
  line-height: 1.85vh ;
}
.option_btn span{
  font-size: 16px;
  font-family: 'SHSCN_Regular';
}
/* 刷新数据图片 */
.flushed{
  margin: 25px 1.88vw 0 0;
}
.flushed img{
 cursor: pointer;
}
/* 头部--结束 */

/* 表格区域--开始 */
.purchaseManage_mid .gateway_form {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-top: 7px;
}
/* 表格区域--结束 */

.lists /deep/ .el-pagination{
  align-items:normal ;
  margin-top: 14px;
  height: 50px !important;
}
</style>