<template>
  <!-- 报警事件-- 表格区域 -->
  <div class="lists">
    <!-- Element ui -- Form表单 -->
    <template>
      <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#4888FF'}" :max-height="tableHeight" v-loading="loading">
        <!-- 索引序号 -->
        <el-table-column type="index" width="80" fixed></el-table-column>
        <!-- 报警编号-->
        <el-table-column prop="ts" label="报警编号" min-width="180">
        </el-table-column>
        <!-- 回路编号 -->
        <el-table-column prop="nmid" label="回路编号" min-width="120">
        </el-table-column>
        <!-- 回路名称 -->
        <el-table-column prop="mname" label="回路名称" min-width="200">
        </el-table-column>
        <!-- 网关编号 -->
        <el-table-column prop="nid" label="网关编号" min-width="220">
        </el-table-column>
        <!-- 网关名称 -->
        <el-table-column prop="nname" label="所属网关" min-width="200">
        </el-table-column>
        <!-- 报警类型 -->
        <el-table-column prop="jtype" label="报警类型" min-width="200">
          <template #default="{row}">{{ warningSort.find(e => e.value === row.jtype).label }}</template>
        </el-table-column>
        <!-- 报警详情 -->
        <el-table-column prop="warningDetail" label="报警详情" min-width="100">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.desc"
              placement="top-start">
              <el-button type="text" size="small">详情</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 报警时间 -->
        <el-table-column prop="title" label="报警时间" width="200" fixed="right">
          <template #default="{row}">{{ $timestamp(row.ts.toString().slice(0,10)) }}</template>
        </el-table-column>
      </el-table>
    </template>
    <!-- Element ui--Pagination分页 -->
    <template>
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
        :current-page="params.page" :page-size="params.size" layout="total, prev, pager, next,sizes,jumper"
        :total="total">
      </el-pagination>
    </template>
  </div>
</template>

<script>
// 引入vuex的辅助函数
import { mapState } from 'vuex';
import { alarmList } from '@/utils/api.js'
import dayjs from 'dayjs'
export default {
  data () {
    return {
      currentPage: 1,//当前页数
      loading: true,//刷新数据
      tableData: [],//表格数据
      total:0,
      params: {
        page: 1,
        size: 10,
        date: '',
        jtype: '',
        status: '',
        nmid: ''
      },
      tableHeight: 0
    };
  },
  created () {
    this.tableHeight = document.documentElement.clientHeight - 310;
    this.getAlarmList()
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.tableHeight = document.documentElement.clientHeight - 310;
    })
  },
  computed: {
   ...mapState('warningInform',['warningSort','warningLevel'])
  },
  watch:{
    params: {
      handler: function () {
        this.getAlarmList()
      },
      deep: true
    }
  },
  methods: {
    // 获取表格数据
    async getAlarmList () {
      this.loading = true
      const res = await alarmList(this.params)
      this.tableData = res.data.data
      this.total = res.data.total
      this.loading = false
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 1) {
        return 'success-row';
      }
      return '';
    },
    // 分页的方法
    handleSizeChange (val) {
      this.params.page = 1
      this.params.size = val
    },
    handleCurrentChange (val) {
      this.params.page = val
    },
  },
};
</script>

<style  scoped>
.el-table /deep/ .warning-row {
  background: #fff;
}

.el-table /deep/ .success-row {
  background: #f1f3f8;
}
.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}
.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-size: 14px;
  font-weight: normal;
}
.lists {
  height: 100%;
  position: relative;
  background-color: #fff;
}
.lists .el-pagination {
  height: 60px;
  position: absolute;
  right: 0;
  bottom: 110px;
  font-family: "Source Han Sans CN-Regular";
}
.lists /deep/ .el-pagination__jump {
  margin-left: 0;
}
.el-table /deep/ .el-table__cell {
  text-align: center;
  padding: 16px 0;
}
</style>